.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  list-style: none;
  padding: 5px;
  margin: 0;
}

.pagination-page {
  border: 1px solid #ccc;
  border-radius: 5px;
  /* padding: 5px 10px; */
  height: 25px;
  width: auto;
  padding: 0 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-active {
  background-color: red;
  color: white;
}

.pagination-container .break {
  margin: 5px;
}
.pagination-container .previous {
  border: 1px solid #ccc;
  border-radius: 5px;
  /* padding: 5px 10px; */
  height: 25px;
  width: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-container .previous a {
  padding-bottom: 3px;
  padding-right: 2px;
}

.pagination-container .next {
  border: 1px solid #ccc;
  border-radius: 5px;
  /* padding: 5px 10px; */
  height: 25px;
  width: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-container .next a {
  padding-bottom: 3px;
  padding-left: 2px;
}